const Button = ({ text, position }) => {
  return (
    <div className={"ButtonContainer"} style={{ justifyContent: position }}>
      <div className="Button">
        <div className="Button-Arrow"></div>
        <p className=" Button-Text">{text}</p>
      </div>
    </div>
  );
};

export default Button;
