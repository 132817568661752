const projects = [
  // NOVAR
  {
    id: 1,
    tags: ["Branding"],
    route: "novar",
    projectName: "Novar",
    projectSubtitleEs: "La IA transforma la industria óptica",
    projectSubtitleEn: "AI transforms the optical industry",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-novar.png",
    description1Es:
      "Novar es una empresa líder en Argentina, especializada en tecnologías ópticas de ultima generación. Su busqueda constante de innovación y nuevos desafios la ha posicionado como marca pionera en América Latina y Europa. Fuimos convocados para renovar la imagen de la empresa, en preparación para un lanzamiento fundamental en su historia: la presentación de Ailens, la primera lente diseñada por inteligencia artificial. Este evento tendría lugar en feria MIDO 22, reconocida como la más importante en la industria óptica. ",
    description1En:
      "Novar is a leading company in Argentina, specializing in cutting-edge optical technologies. Its constant pursuit of innovation and new challenges has positioned it as a pioneering brand in Latin America and Europe. We were called upon to revamp the company's image in preparation for a pivotal launch in its history: the introduction of Ailens, the first lens designed by artificial intelligence. This event would take place at the MIDO 22 fair, recognized as the most important in the optical industry.",
    description2Es: `Uno de los principales desafíos fue lograr que Novar destacara entre todos los expositores de la feria. Para ello, seleccionamos el distintivo color "Persian Indigo" como el tono principal de la marca, transmitiendo innovación y diferenciándola de la competencia. Complementamos el branding con texturas que evocan las mallas utilizadas en la construcción de las lentes, creando una imagen de alto nivel pero llamativa. Además, optamos por la versátil tipografía Gotham para reflejar profesionalismo y adaptabilidad`,
    description2En: `One of the main challenges was ensuring that Novar stood out among all the exhibitors at the fair. To achieve this, we selected the distinctive color "Persian Indigo" as the brand's primary tone, conveying innovation and setting it apart from the competition. We complemented the branding with textures that evoke the meshes used in the construction of lenses, creating a high-end yet striking image. Additionally, we opted for the versatile Gotham typography to reflect professionalism and adaptability.`,
    description3Es:
      "En el caso de Aliens, conservamos elementos clave de Novar y adoptamos un enfoque disruptivo con un vibrante turquesa que simboliza la innovación y el progreso. También incorporamos las mallas del branding de Novar, las cuales se entrelazan en Aliens para representar la tecnología de vanguardia de esta nueva era",
    description3En:
      "In the case of Ailens, we retained key elements of Novar and adopted a disruptive approach with a vibrant turquoise that symbolizes innovation and progress. We also incorporated the Novar branding meshes, which intertwine in Ailens to represent the cutting-edge technology of this new era.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-novar.png",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Branding",
    service2En: "Branding",
    service3Es: "Diseño de Stands",
    service3En: "Stand Design",
    service4Es: "Packaging",
    service4En: "Packaging",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Buenos Aires, Argentina",
    locationEn: "Buenos Aires, Argentina",
    templateNumber: "3",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar2.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar3.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar5.webp",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar4.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar8.webp",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar9.webp",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar10.webp",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar11.webp",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar12.webp",
    image13:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar13.webp",
    image14:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar14.webp",
    image15:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-novar15.webp",
  },
  // SENSORY FOOD
  {
    id: 2,
    tags: ["Apps", "Branding"],
    route: "sensory-food",
    projectName: "Sensory Food",
    projectSubtitleEs: "Una experiencia Sensorial",
    projectSubtitleEn: "A sensory experience",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-sensoryfood.png",
    description1Es: `Sensory Food es una aplicación desarrollada en Valencia, España, que se enfoca en agilizar y simplificar la gestión interna de catas de alimentos. La marca se presenta como una plataforma confiable e innovadora, fomentando la colaboración entre empresas y catadores.`,
    description1En: `Sensory Food is an app developed in Valencia, Spain, that focuses on streamlining and simplifying the internal management of food tastings. The brand presents itself as a reliable and innovative platform, fostering collaboration between companies and tasters.`,
    description2Es: `Fuimos convocados para crear la identidad integral de la marca y luego llevar a cabo el diseño de la aplicación. Comenzamos con el desarrollo de un logotipo, compuesto por un isotipo pregnante. La forma "S" se inspira en las morfologías de un globo de diálogo o reseñas, simbolizando las opiniones de los catadores. Esta morfología también representa la unión entre empresas y colaboradores, reflejando el espíritu colaborativo de Sensory Food. En cuanto a las elecciones cromáticas, las tonalidades azules se inspiran en el mundo corporativo, enfatizando la profesionalidad y confianza de la marca, pero con un toque moderno y juvenil. Además, el uso del degradé agrega una dimensión sensorial a la marca.`,
    description2En: `We were tasked with creating the brand's comprehensive identity and then designing the application. We started by developing a logo, featuring a distinctive isotype. The "S" shape is inspired by the morphologies of a dialogue bubble or reviews, symbolizing the opinions of tasters. This morphology also represents the connection between companies and collaborators, reflecting the collaborative spirit of Sensory Food. Regarding color choices, the blue tones are inspired by the corporate world, emphasizing the brand's professionalism and trustworthiness, but with a modern and youthful touch. Additionally, the use of gradients adds a sensory dimension to the brand.`,
    description3Es:
      "Para la aplicación, diseñamos una estructura con un home principal que despliega las catas disponibles y permite su gestión. Las pantallas de encuestas se mantuvieron simples e intuitivas para facilitar la evaluación de las catas. Además, en diversas acciones de la aplicación incorporamos ilustraciones modernas y amigables para darle un toque divertido y humanizar la marca",
    description3En:
      "For the application, we designed a structure with a main homepage that displays the available tastings and allows for their management. The survey screens were kept simple and intuitive to facilitate the evaluation of the tastings. Moreover, various actions within the app feature modern and friendly illustrations to add a fun touch and humanize the brand.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-sensoryfood.png",

    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Logo",
    service2En: "Logo",
    service3Es: "Branding",
    service3En: "Branding",
    service4Es: "Diseño de app",
    service4En: "App Design",
    service5Es: "Tienda Online",
    service5En: "Online Store",
    service6Es: "",
    service6En: "",
    locationEs: "Valencia, España",
    locationEn: "Valencia, Spain",
    templateNumber: "1",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sensoryfood1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sensoryfood2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sensoryfood3.png",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sensoryfood4.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sensoryfood5.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sensoryfood6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sensoryfood7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sensoryfood8.png",
  },
  // ELOVATE LIFE
  {
    id: 3,
    tags: ["Branding", "Web&Ecommerce"],
    route: "elovate-life",
    projectName: "Elovate Life",
    projectSubtitleEs: "Elovate your wellness",
    projectSubtitleEn: "Elovate your wellness",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-elovate.png",
    description1Es:
      "Elovate Life es una empresa estadounidense comprometida con promover un estilo de vida saludable y equilibrado. Ofrece productos naturales, sin gluten y veganos diseñados para regular los niveles de azúcar en sangre, así como también suplementos vitamínicos.",
    description1En:
      "Elovate Life is a U.S.-based company committed to promoting a healthy and balanced lifestyle. They offer natural, gluten-free, and vegan products designed to regulate blood sugar levels, as well as vitamin supplements.",
    description2Es: `Fuimos convocados para llevar a cabo el rebranding completo de la marca, abarcando desde el diseño de un nuevo logotipo hasta el packaging de sus productos, piezas digitales y su tienda online.
        En relación al logotipo, decidimos mantener el diseño original que ya estaba establecido en el mercado y agregamos la palabra "Life" para representar otra faceta de la marca. "Life" se incorporó con una tipografía caligráfica en tonos suaves, destacando un aspecto más amigable y consciente, mientras que "Elovate", con una tipografía más pesada y firme, comunica el impacto contundente de sus productos en el bienestar.`,
    description2En: `We were tasked with carrying out a complete rebranding of the company, encompassing everything from designing a new logo to creating product packaging, digital assets, and their online store. Regarding the logo, we decided to keep the original design that was already established in the market and added the word "Life" to represent another facet of the brand. "Life" was incorporated using a calligraphic font in soft tones, highlighting a more friendly and conscious aspect, while "Elovate," with a heavier and more robust font, communicates the strong impact of their products on wellness.`,
    description3Es:
      "En cuanto al packaging, buscamos equilibrios entre blanco y colores vibrantes para lograr una apariencia limpia pero impactante. Las formas angulosas y diagonales se contrastaron con iconografía e ilustraciones sutiles, manteniendo el juego de contrastes propuesto en el logotipo. En relación con la tienda online, elegimos una imagen moderna donde el color corporativo predominara, y, al mismo tiempo, los productos fueran los protagonistas. Además, incorporamos imágenes de personas realizando deporte, lo cual transmite una sensación de superación y bienestar.",
    description3En:
      "For the packaging, we sought a balance between white and vibrant colors to achieve a clean yet impactful look. Angular and diagonal shapes were contrasted with subtle iconography and illustrations, maintaining the interplay of contrasts proposed in the logo. As for the online store, we chose a modern design where the corporate color predominates, while ensuring the products take center stage. Additionally, we incorporated images of people engaging in sports, conveying a sense of achievement and well-being.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-elovate.png",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Logo",
    service2En: "Logo",
    service3Es: "Branding",
    service3En: "Branding",
    service4Es: "Packaging",
    service4En: "Packaging",
    service5Es: "Tienda online",
    service5En: "Online Store",
    service6Es: "",
    service6En: "",
    locationEs: "Miami, Estados Unidos",
    locationEn: "Miami, United States",
    templateNumber: "3",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate3.png",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate5.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate4.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate8.png",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate9.png",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate10.png",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate11.png",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate12.png",
    image13:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate13.png",
    image14:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate14.png",
    image15:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-elovate15.png",
  },
  // MADERO TANGO
  {
    id: 4,
    tags: ["Branding", "Web&Ecommerce"],
    route: "madero-tango",
    projectName: "Madero Tango",
    projectSubtitleEs: "Donde el tango vive",
    projectSubtitleEn: "Where tango lives",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-maderotango.png",
    description1Es:
      "Madero Tango, empresa líder en entretenimiento en Argentina y referente en el ámbito del tango, nos eligió para abordar un desafío integral: el rebranding de sus dos sedes, tanto en Puerto Madero como en las Cataratas del Iguazú. Este proyecto abarcó la renovación completa de la imagen, desde marquesinas y fachadas hasta cartelería, banners, papelería institucional y contenido digital, como páginas web y redes sociales. También colaboramos en el diseño de sus fiestas temáticas de fin de año, creamos stands para ferias turísticas y participamos en el lanzamiento de su gira por Sudamérica.",
    description1En:
      "Madero Tango, a leading entertainment company in Argentina and a prominent name in the tango scene, chose us to tackle a comprehensive challenge: the rebranding of its two locations, both in Puerto Madero and Iguazú Falls. This project encompassed a complete image overhaul, from marquees and facades to signage, banners, stationery, and digital content, including websites and social media. We also collaborated on designing their end-of-year themed parties, created stands for tourism fairs, and participated in the launch of their South American tour.",
    description2Es: `En el proceso de rediseño, buscamos reflejar la elegancia y la categoría  que caracterizan a Madero Tango. Optamos por tipografías caligráficas que transmitieran movimiento y vitalidad, combinadas con serifs modernas. Mientras que en Puerto Madero mantuvimos una estética más clásica inspirada en el tango, en la sede de Iguazú, la caligrafía adoptó una impronta más salvaje. Otra característica distintiva fue la inclusión de misceláneas, inspiradas en el fileteado porteño pero reinterpretadas de manera contemporánea. En Puerto Madero, incorporamos elementos curvos similares a flores, mientras que en Iguazú utilizamos líneas finas que evocan el movimiento de las cataratas.`,
    description2En: `In the redesign process, we aimed to reflect the elegance and sophistication that characterize Madero Tango. We opted for calligraphic fonts that conveyed movement and vitality, combined with modern serifs. While we maintained a more classic aesthetic inspired by tango at Puerto Madero, the Iguazú location embraced a wilder calligraphy style. Another distinctive feature was the inclusion of miscellanea, inspired by porteño fileteado but reinterpreted in a contemporary way. In Puerto Madero, we incorporated curved elements reminiscent of flowers, while in Iguazú, we used fine lines evoking the movement of the falls.`,
    description3Es:
      "Otra decisión significativa fue la introducción del color dorado para destacar puntos focales y resaltar la elegancia de la marca. Además, las fotografías, de gran impacto, destacan con la presencia de negro, creando siluetas que capturan la atención y se convierten en protagonistas del diseño.",
    description3En:
      "A significant decision was the introduction of gold to highlight focal points and accentuate the brand's elegance. Additionally, impactful photographs stand out with the presence of black, creating silhouettes that capture attention and become focal points in the design.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-madero-tango.png",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Branding",
    service2En: "Branding",
    service3Es: "Cartelería",
    service3En: "Signage",
    service4Es: "Web",
    service4En: "Website",
    service5Es: "Social Media",
    service5En: "Social Media",
    service6Es: "",
    service6En: "",
    locationEs: "Buenos Aires, Argentina",
    locationEn: "Buenos Aires, Argentina",
    templateNumber: "3",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango3.png",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango5.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango4.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango8.png",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango9.png",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango10.png",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango11.png",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango12.png",
    image13:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango13.png",
    image14:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango14.png",
    image15:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderotango15.png",
  },
  // TRYPO
  {
    id: 5,
    tags: ["Web&Ecommerce", "Apps"],
    route: "trypo",
    projectName: "Trypo",
    projectSubtitleEs: "Donde el viaje comienza",
    projectSubtitleEn: "Where the journey begins",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-trypo.png",
    description1Es:
      "Trypo desarrollada en Santiago Chile, es una plataforma pionera en la región, especializada en la reserva de experiencias en la naturaleza, que van desde glamping hasta tirolesa, trekkings y alquiler de motorhomes. Se destaca por su enfoque ecofriendly y por su espíritu aventurero que invita a las personas a explorar el mundo de una manera única.",
    description1En:
      "Trypo, developed in Santiago, Chile, is a pioneering platform in the region, specializing in booking nature experiences ranging from glamping to zip-lining, trekking, and motorhome rentals. It stands out for its eco-friendly approach and adventurous spirit, inviting people to explore the world in a unique way.",
    description2Es: `Fuimos convocados con el desafío de crear una imagen distintiva para la marca y proyectar integralmente la plataforma. Abarcamos desde su aspecto visual hasta la estructura de navegación, con el objetivo de ofrecer una experiencia intuitiva y sencilla para el usuario. Iniciamos el proceso seleccionando un color dinámico y actual que reflejara la naturaleza, optando por un verde saturado e intenso que se aplicó en diversas instancias de la plataforma. Este tono se contrastó con un celeste igual de saturado para resaltar los puntos clave.
    En cuanto a los elementos gráficos, desarrollamos un sistema iconográfico minimalista, lineal y con terminaciones redondeadas. De este modo, se representó cada actividad disponible de una manera moderna y amigable. Además, incorporamos ilustraciones en diversas operaciones para infundir un toque lúdico y relajado a la marca.`,
    description2En: `We were tasked with creating a distinctive image for the brand and projecting the platform integrally. This involved everything from its visual appearance to its navigation structure, with the goal of providing an intuitive and simple user experience. We began by selecting a dynamic and contemporary color that reflects nature, opting for a saturated and intense green applied throughout various parts of the platform. This tone was contrasted with a similarly saturated sky blue to highlight key points. For graphical elements, we developed a minimalist, linear iconographic system with rounded edges, representing each available activity in a modern and friendly manner. Additionally, we incorporated illustrations in various functions to add a playful and relaxed touch to the brand.`,
    description3Es: `La estructura de la plataforma se diseñó con un Home que lista las distintas experiencias, permitiendo a los usuarios reservar, visualizar u obtener detalles de cada una. Además, cada experiencia incluye calificaciones y reseñas de otros usuarios, fomentando un sentido de comunidad y colaboración. Implementamos también un módulo de perfil de usuario que facilita la gestión de experiencias favoritas, programadas e históricas.`,
    description3En: `The platform's structure was designed with a homepage listing the different experiences, allowing users to book, view, or get details about each one. Each experience also includes ratings and reviews from other users, fostering a sense of community and collaboration. We also implemented a user profile module to facilitate the management of favorite, scheduled, and historical experiences.`,
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-trypo.png",
    service1Es: "Branding",
    service1En: "Branding",
    service2Es: "Diseño de app",
    service2En: "App Design",
    service3Es: "Web",
    service3En: "Website",
    service4Es: "",
    service4En: "",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Santiago, Chile",
    locationEn: "Santiago, Chile",
    templateNumber: "2",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo3.svg",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo4.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo5.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo8.png",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo9.png",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo10.png",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo11.png",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-trypo12.png",
  },
  // TU ESPACIO
  {
    id: 6,
    tags: ["Branding", "Web&Ecommerce"],
    route: "tu-espacio",
    projectName: "Tu Espacio",
    projectSubtitle: {
      es: "",
      en: "",
    },
    projectSubtitleEs: "Reinventando el Diseño de Interiores",
    projectSubtitleEn: "Reinventing interior design",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-tuespacio.webp",

    description1Es:
      "Tu Espacio es una marca de diseño de interiores nacida en Tucumán, Argentina, con un enfoque claro: reinventar el diseño de interiores a través de una propuesta digital y personalizada. Especialmente dirigida a un público joven, familiarizado con las nuevas tendencias y con un espíritu emprendedor, nos embarcamos en el desafío de crear una identidad completa para este proyecto.",
    description1En:
      "Tu Espacio is an interior design brand born in Tucumán, Argentina, with a clear focus: to reinvent interior design through a digital and personalized approach. Aimed specifically at a young audience familiar with new trends and possessing an entrepreneurial spirit, we undertook the challenge of creating a complete identity for this project.",
    description2Es:
      "Desde el principio, nos planteamos la tarea de combinar la parte racional y resolutiva con el lado más soñador de la marca, cuyo objetivo es ayudar a las personas a conseguir el hogar de sus sueños. Desarrollamos un logo con líneas sencillas que evoca la precisión de los planos, y al mismo tiempo que simboliza una puerta abierta que invita a conectar con sus sueños. En cuanto a los recursos gráficos, logramos construir una identidad visual que se caracteriza por su simplicidad y minimalismo, contrastando con ilustraciones e iconografías alegres y divertidas. La paleta cromática también desempeña un papel importante al equilibrar los tonos cálidos y fríos, creando un ambiente armonioso entre los planos y las figuras.",
    description2En:
      "From the outset, we aimed to combine the rational and problem-solving aspects with the brand's more dreamy side, whose goal is to help people achieve the home of their dreams. We developed a logo with simple lines that evoke the precision of blueprints while also symbolizing an open door inviting connection with one’s dreams. Regarding graphic resources, we built a visual identity characterized by its simplicity and minimalism, contrasted with cheerful and playful illustrations and iconography. The color palette also plays a significant role in balancing warm and cool tones, creating a harmonious environment between the plans and figures.",
    description3Es: "",
    description3En: "",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-tuespacio.webp",

    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Logo",
    service2En: "Logo",
    service3Es: "Branding",
    service3En: "Branding",
    service4Es: "Web",
    service4En: "Website",
    service5Es: "Social media",
    service5En: "Social media",
    service6Es: "",
    service6En: "",
    locationEs: "Tucumán, Argentina",
    locationEn: "Tucumán, Argentina",
    templateNumber: "2",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tuespacio1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tuespacio2.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tuespacio3.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tuespacio4.webp",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tuespacio5.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tuespacio6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tuespacio7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projetcs-tuespacio8.webp",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projetcs-tuespacio9.webp",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projetcs-tuespacio10.webp",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projetcs-tuespacio11.webp",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projetcs-tuespacio12.webp",
  },
  // DALEPLAST
  {
    id: 7,
    tags: ["Web&Ecommerce", "Branding"],
    route: "daleplast",
    projectName: "Daleplast",

    projectSubtitleEs: "Transformando la Imagen del Plástico",
    projectSubtitleEn: "Transforming the image of plastic",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-daleplast.webp",

    description1Es:
      "Daleplast es una empresa argentina dedicada a la producción de bolsas de plástico oxibiodegradable. Con un fuerte enfoque con el cuidado del medio ambiente, Daleplast ofrece opciones eco-friendly de alta calidad, motivando a sus clientes a adoptar prácticas sostenibles y promoviendo el uso responsable del plástico en la comunidad. ",
    description1En:
      "Daleplast is an Argentine company dedicated to the production of oxo-biodegradable plastic bags. With a strong focus on environmental care, Daleplast offers high-quality eco-friendly options, encouraging its customers to adopt sustainable practices and promoting responsible plastic use within the community.",
    description2Es:
      "Fuimos convocado para llevar a cabo el rediseño de la imagen de marca de Daleplast y llevarla a las plataformas digitales. Nuestro enfoque se basó en mantener la esencia del antiguo logo, pero desde una perspectiva más actual. Utilizamos una tipografía sans serif con remates redondeados para transmitir amigabilidad y actualidad, y fortalecimos el isotipo para representar los valores actuales de la marca. En cuanto al branding, incorporamos elementos visuales dinámicos propios del isologo, y creamos una paleta de colores amplia que incluye los colores originales de la marca, como el verde oscuro y el rojo, junto con tonos adicionales como el verde agua, destacando el compromiso eco-friendly de Dlaeplat. La imagen gráfica se caracteriza por su limpieza y elegancia, con un predominio del blanco y el uso de tipografías clásicas y sobrias, reflejando los valores de eficiencia y calidad de la marca.",
    description2En:
      "We were tasked with redesigning Daleplast's brand image and transitioning it to digital platforms. Our approach was to retain the essence of the old logo while updating it with a more contemporary perspective. We used a sans serif font with rounded edges to convey friendliness and modernity, and strengthened the isotype to reflect the brand’s current values. For the branding, we incorporated dynamic visual elements from the isologo and created an extensive color palette that includes the brand’s original colors, such as dark green and red, along with additional tones like aqua green, highlighting Daleplast's eco-friendly commitment. The graphic image is characterized by its cleanliness and elegance, with a predominance of white and the use of classic and sober fonts, reflecting the brand’s values of efficiency and quality.",
    description3Es: "",
    description3En: "",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-daleplast.webp",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Logo",
    service2En: "Logo",
    service3Es: "Branding",
    service3En: "Branding",
    service4Es: "Web",
    service4En: "Website",
    service5Es: "Social media",
    service5En: "Social media",
    service6Es: "",
    service6En: "",
    locationEs: "Tierra del Fuego, Argentina",
    locationEn: "Tierra del Fuego, Argentina",
    templateNumber: "2",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast2.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast3.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast4.webp",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast5.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast8.webp",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast9.webp",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast10.webp",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast11.webp",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-daleplast12.webp",
  },
  // INADEA
  {
    id: 8,
    tags: ["Branding", "Web&Ecommerce"],
    route: "inadea",
    projectName: "Inadea",

    projectSubtitleEs: "Cardioprotección para salvar vidas",
    projectSubtitleEn: "Cardioprotection to save lives",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-inadea.webp",

    description1Es:
      "Inadea, es una institución médica argentina formada por un equipo de profesionales de la salud especializados en la creación de áreas de cardioprotección en diversos entornos, desde empresas hasta instituciones deportivas y educativas. Lo que los distingue es su enfoque humanitario y su compromiso diario de salvar vidas. ",
    description1En:
      "Inadea is an Argentine medical institution formed by a team of health professionals specialized in creating cardioprotection areas in various environments, from businesses to sports and educational institutions. What sets them apart is their humanitarian approach and daily commitment to saving lives.",
    description2Es:
      "Fuimos convocados para realizar el rediseño de la imagen de la institución y su web, con el propósito de modernizarla y representar los valores fundamentales de Inadea. En este proceso, nuestra estrategia fue doble: transmitir confianza y profesionalismo, mientras destacamos el elemento humano que es el pilar de la marca. Optamos por una tipografía san serif, de aspecto cercano y profesional, y elegimos una paleta de colores que abarca tonos azules y verdes, evocando tanto el mundo médico como una sensación de responsabilidad y seguridad. Para reflejar el toque humano, incorporamos una iconografía amigable y diversas formas con vértices redondeados. Además, utilizamos círculos y degradados para sumar calidez y proximidad.",
    description2En: `We were tasked with redesigning the institution’s image and website to modernize it and represent Inadea's core values. Our strategy was twofold: to convey trust and professionalism while highlighting the human element that is the brand’s cornerstone. We chose a sans serif typeface with a friendly and professional appearance and selected a color palette that includes shades of blue and green, evoking both the medical world and a sense of responsibility and security. To reflect the human touch, we incorporated friendly iconography and various shapes with rounded edges. Additionally, we used circles and gradients to add warmth and proximity.`,
    description3Es:
      "Finalmente, las fotografías seleccionadas no solo incluyen personas para humanizar la propuesta, sino que también se sometieron a un proceso de edición de luz para transmitir transparencia y generar confianza. ",
    description3En:
      "Finally, the selected photographs not only include people to humanize the proposal but also underwent a light editing process to convey transparency and build trust.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-inadea.png",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Branding",
    service2En: "Branding",
    service3Es: "Web",
    service3En: "Website",
    service4Es: "Cartelería",
    service4En: "Signage",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Buenos Aires, Argentina",
    locationEn: "Buenos Aires, Argentina",
    templateNumber: "2",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea3.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea2.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea4.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea5.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea8.webp",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea9.webp",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea10.webp",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea11.webp",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-inadea12.webp",
  },
  // REPUBLICA DEL TANNAT
  {
    id: 9,
    tags: ["Branding"],
    route: "republica-del-tannat",
    projectName: "República del Tannat",
    projectSubtitleEs: "Un Tannat elegante y auténtico",
    projectSubtitleEn: "An elegant and authentic Tannat",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-tannat.png",
    description1Es: `"República del Tannat", un vino originario de Carmelo, Uruguay, nos desafió a crear su imagen de marca. La bodega, arraigada en la pasión por el vino, buscaba una representación de calidad y sofisticación para sus productos.`,
    description1En: `"República del Tannat," a wine from Carmelo, Uruguay, challenged us to create its brand image. The winery, rooted in a passion for wine, sought a representation of quality and sophistication for its products.`,
    description2Es:
      "En el proceso de diseño del logotipo, elegimos una tipografía elegante y minimalista, destacando detalles como la A sin travesaño. Además, el espacio entre las letras actúa como un respiro fresco, invitando a descubrir más. Complementamos esto con un isologo que incorpora la bandera de Uruguay, ligeramente deformada para transmitir un sentido de origen y movimiento, resaltando la vitalidad de este vino.",
    description2En:
      "In designing the logo, we chose an elegant and minimalist typeface, highlighting details such as the A without a crossbar. Additionally, the spacing between the letters acts as a breath of fresh air, inviting further exploration. We complemented this with a logo that incorporates the Uruguayan flag, slightly distorted to convey a sense of origin and movement, emphasizing the vitality of this wine.",
    description3Es:
      "Extendimos nuestro trabajo a las etiquetas, optando por el blanco para la reserva y negro con toques dorados para la guarda. Mantuvimos las decisiones del logo en las etiquetas, fusionando el espacio, la elegancia y el minimalismo en un diseño armonioso. Introdujimos textura con sutiles rayas horizontales y también ofrecimos una opción con una ilustración a mano que captura la esencia del trabajo en las viñas, aportando autenticidad y sensibilidad a la marca.",
    description3En:
      "We extended our work to the labels, opting for white for the reserva and black with golden accents for the guarda. We maintained the design decisions from the logo on the labels, merging space, elegance, and minimalism into a harmonious design. We introduced texture with subtle horizontal stripes and also offered an option with a hand-drawn illustration that captures the essence of vineyard work, adding authenticity and sensitivity to the brand.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-tannat.png",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Logo",
    service2En: "Logo",
    service3Es: "Branding",
    service3En: "Branding",
    service4Es: "Packaging",
    service4En: "Packaging",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Carmelo, Uruguay",
    locationEn: "Carmelo, Uruguay",
    templateNumber: "1",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tannat1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tannat2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tannat3.png",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tannat4.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tannat5.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tannat6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tannat7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tannat8.png",
  },
  // NATURGREEN
  {
    id: 10,
    tags: ["Branding"],
    route: "naturgreen",
    projectName: "Naturgreen",
    projectSubtitle: {
      es: "Agricultura sostenible y biodiversidad",
      en: "Sustainable agriculture and biodiversity",
    },
    projectSubtitleEs: "",
    projectSubtitleEn: "",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-naturgreen.webp",

    description1Es:
      "Naturgreen - Iberhanse, líder en la producción de cítricos y frutas con sede en Sevilla, es reconocido a nivel internacional por su combinación única de innovación y tradición. La empresa se destaca por implementar prácticas agrícolas sostenibles, siendo un pionero en la reintegración de la biodiversidad en la agricultura.",
    description1En:
      "Naturgreen - Iberhanse, a leader in citrus and fruit production based in Seville, is internationally recognized for its unique blend of innovation and tradition. The company stands out for implementing sustainable agricultural practices and is a pioneer in reintegrating biodiversity into agriculture.",
    description2Es:
      "Junto al supermercado alemán Edeka y la ONG WWF, llevan adelante el proyecto Zitrus donde se enfocan en la producción y exportación de cítricos mediante una agricultura bioinclusiva. Este modelo se basa en prácticas naturales y sostenibles para el entorno, contribuyendo a la recuperación de la flora y fauna en las áreas de cultivo. ",
    description2En:
      "Alongside the German supermarket Edeka and the NGO WWF, they are carrying out the Zitrus project, focusing on the production and export of citrus through bio-inclusive agriculture. This model is based on natural and sustainable practices for the environment, contributing to the recovery of flora and fauna in the cultivation areas.",
    description3Es:
      "Nuestra contribución se centró en diseñar una experiencia única para los consumidores durante la compra de las naranjas. Creamos una presentación que reemplaza las redes tradicionales por alveolos donde se colocan las naranjas. Cada naranja se apoya sobre una representación ilustrada de flora o fauna preservada en las áreas de cultivo. Además, incluimos frases positivas que alientan la convivencia entre la fauna, la flora y el cultivo. Estas ilustraciones, realizadas a mano con trazos simples pero detallados, fueron diseñadas para ser amigables y atractivas, creando una conexión emocional con los consumidores.",
    description3En:
      "Our contribution focused on designing a unique consumer experience during the purchase of oranges. We created a presentation that replaces traditional nets with cells where the oranges are placed. Each orange rests on an illustrated representation of preserved flora or fauna in the cultivation areas. Additionally, we included positive phrases encouraging coexistence between fauna, flora, and cultivation. These hand-drawn illustrations, with simple yet detailed strokes, were designed to be friendly and appealing, creating an emotional connection with consumers.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-naturgreen.webp",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Ilustración",
    service2En: "Illustration",
    service3Es: "Packaging",
    service3En: "Packaging",
    service4Es: "",
    service4En: "",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Sevilla, España",
    locationEn: "Sevilla, Spain",
    templateNumber: "1",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-naturgreen1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-naturgreen2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-naturgreen3.png",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-naturgreen4.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-naturgreen5.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-naturgreen6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-naturgreen7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-naturgreen8.png",
  },
  // TIESEN
  {
    id: 11,
    tags: ["Apps"],
    route: "tiesen",
    projectName: "Tiesen",
    projectSubtitleEs: "Todos los Expertos en Hogar en un Solo Lugar",
    projectSubtitleEn: "All home experts in one place",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-tiesen.png",
    description1Es: `Tiesen es una aplicación desarrollada en Santiago, Chile, la cual conecta usuarios y profesionales del hogar, como electricistas, carpinteros y pintores. Nuestro desafío fue proyectar esta aplicación de manera integral, desde la fase de investigación de mercado hasta su diseño final.`,
    description1En:
      "Tiesen is an application developed in Santiago, Chile, that connects users with home professionals such as electricians, carpenters, and painters. Our challenge was to project this application comprehensively, from market research to final design.",
    description2Es:
      "Nos centramos en satisfacer las necesidades de dos tipos de usuarios: para los clientes, creamos una experiencia simple y rápida para encontrar servicios profesionales, además de brindarles la oportunidad de evaluar la calidad del trabajo. Para los profesionales, desarrollamos una plataforma atractiva que les permitiera mostrar sus habilidades y captar clientes potenciales.",
    description2En:
      "We focused on meeting the needs of two types of users: for customers, we created a simple and quick experience for finding professional services, while also providing the opportunity to evaluate the quality of the work. For professionals, we developed an engaging platform that allows them to showcase their skills and attract potential clients.",
    description3Es:
      "En cuanto a las elecciones gráficas, optamos por una paleta de colores en tonos verde agua y celeste para proyectar una imagen profesional y dinámica. Para añadir profundidad, incorporamos fondos en degradé y una textura de diagonales que resalta la rapidez y eficiencia del servicio. Otra decisión relevante fue la creación de un sistema iconográfico lineal y minimalista con toques de color, presente en diversas secciones de la aplicación, como la selección de perfiles y especialidades.",
    description3En:
      "Regarding graphic choices, we opted for a color palette in aqua green and sky blue to project a professional and dynamic image. To add depth, we incorporated gradient backgrounds and a diagonal texture that emphasizes the speed and efficiency of the service. Another key decision was the creation of a linear and minimalist iconographic system with color accents, present in various sections of the application, such as profile and specialty selection.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-tiesen.png",
    service1Es: "Diseño de app",
    service1En: "App Design",
    service2Es: "",
    service2En: "",
    service3Es: "",
    service3En: "",
    service4Es: "",
    service4En: "",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Santiago, Chile",
    locationEn: "Santiago, Chile",
    templateNumber: "1",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tiesen1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tiesen2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tiesen3.png",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tiesen4.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tiesen5.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tiesen6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tiesen7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-tiesen8.png",
  },
  // MADERO VIAGENS
  {
    id: 12,
    tags: ["Branding"],
    route: "madero-viagens",
    projectName: "Madero Viagens",
    projectSubtitleEs: "Experiencia aeropuerto",
    projectSubtitleEn: " Airport experience",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-maderoviajens.webp",
    description1Es:
      "Madero Viagens, una agencia de viajes ubicada en el icónico punto turístico de Galerías Pacífico en Buenos Aires, nos convocó para realizar el rediseño completo de su marca, el diseño del local y una innovadora instalación que funcionaría a modo de stand. La agencia buscaba una imagen moderna y fresca, así como lograr una experiencia única para el cliente.",
    description1En:
      "Madero Viagens, a travel agency located in the iconic Galerías Pacífico in Buenos Aires, commissioned us to completely redesign their brand, design their premises, and create an innovative installation that would function as a stand. The agency sought a modern and fresh image, aiming to provide a unique customer experience.",
    description2Es: `Comenzamos con el rediseño del logo, manteniendo su esencia pero simplificándolo para crear un diseño minimalista y que aún evocara el emblemático Puerto Madero. La paleta de colores contrastantes, pensada para lograr impacto visual, se formó con un azul de tintes amarillos y tonos secundarios en naranja. 
      Para el diseño del local, nos inspiramos en la lógica de un aeropuerto. Utilizamos carteles y mostradores que simulan el proceso de embarque. Para reforzar esta experiencia, introducimos una cinta transportadora y diversas imágenes en blanco y negro que no solo agregan profundidad sino que también simulan ventanas con vistas a aviones despegando y mangas de embarque.`,
    description2En: `We began with redesigning the logo, retaining its essence but simplifying it to create a minimalist design that still evokes the iconic Puerto Madero. The contrasting color palette, designed to create visual impact, features a yellow-tinted blue and secondary orange tones. For the premises design, we drew inspiration from the logic of an airport. We used signs and counters that simulate the boarding process. To enhance this experience, we introduced a conveyor belt and various black-and-white images that not only add depth but also simulate windows with views of departing airplanes and boarding bridges.`,
    description3Es: `En cuanto a la instalación, ubicada en la misma galería, se incluyó una figura corpórea de la trompa de un avión. En su interior, se ubicaron asientos y ventanas, completando la experiencia de aeropuerto y creando un ambiente único para los visitantes.`,
    description3En: `Regarding the installation, located within the same gallery, we included a life-sized figure of an airplane’s nose. Inside, we placed seats and windows, completing the airport experience and creating a unique environment for visitors.`,
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-maderoviajens.webp",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Logo",
    service2En: "Logo",
    service3Es: "Branding",
    service3En: "Branding",
    service4Es: "Social media",
    service4En: "Social media",
    service5Es: "Packaging",
    service5En: "Packaging",
    service6Es: "",
    service6En: "",
    locationEs: "Buenos Aires, Argentina",
    locationEn: "Buenos Aires, Argentina",
    templateNumber: "2",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens3.png",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens4.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens5.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens8.png",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens9.png",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens10.png",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens11.png",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-maderoviagens12.png",
  },
  // SANDWICH TIME
  {
    id: 13,
    tags: ["Branding", "Web&Ecommerce"],
    route: "sanwich-time",
    projectName: "SandwichTime",
    projectSubtitleEs: "Amor por la cocina real",
    projectSubtitleEn: "Love for real cooking",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-sanwichtime.webp",
    description1Es:
      "Sandwich Time es una cadena de hamburgueserías con sedes en Santiago y Talca, Chile. Se distinguen por ofrecer propuestas con ingredientes de alta calidad y el auténtico sabor casero. Fuimos convocados para llevar a cabo una renovación completa de la marca y desarrollar su tienda de pedidos en línea.",
    description1En:
      "Sandwich Time is a chain of burger restaurants with locations in Santiago and Talca, Chile. They are distinguished by offering high-quality ingredients and the authentic homemade taste. We were commissioned to undertake a complete brand renovation and develop their online ordering store.",
    description2Es: `Creamos una paleta de colores audaces, donde el amarillo se convirtió en el color distintivo de la marca, contrastando con el negro para transmitir una imagen poderosa. Para resaltar la elegancia propia de la marca, seleccionamos una tipografía caligráfica que aporta delicadeza y la combinamos con una tipografía sans-serif condensada que agrega modernidad y legibilidad.En cuanto a las imágenes, elegimos cuidadosamente fotografías que destacan la calidad premium de los ingredientes, así como imágenes que muestran el trabajo en la cocina. El objetivo de esto fue humanizar la marca y compartir su historia casera.`,
    description2En: `We created a bold color palette, with yellow becoming the brand’s distinctive color, contrasting with black to convey a powerful image. To highlight the brand's inherent elegance, we selected a calligraphic typeface that adds delicacy and combined it with a condensed sans-serif typeface that brings modernity and readability. For the imagery, we carefully chose photographs that highlight the premium quality of the ingredients, as well as images showing the work in the kitchen. The goal was to humanize the brand and share its homemade story.`,
    description3Es: `Finalmente, para fortalecer la identidad de la marca, creamos composiciones con imágenes e ilustraciones que aportaron un toque distintivo y lúdico. Además, incorporamos frases destacadas como "Amor por la cocina real", que resaltan aún más sus valores fundamentales.`,
    description3En: `Finally, to strengthen the brand identity, we created compositions with images and illustrations that added a distinctive and playful touch. We also incorporated standout phrases like "Love for Real Cooking," which further emphasize the brand’s core values.`,
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-sandwichtime.webp",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Branding",
    service2En: "Branding",
    service3Es: "Web",
    service3En: "Website",
    service4Es: "Social media",
    service4En: "Social media",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Santiago, Chile",
    locationEn: "Santiago, Chile",
    templateNumber: "3",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich2.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich3.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich5.webp",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich4.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich8.webp",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich9.webp",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich10.webp",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich11.webp",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich12.webp",
    image13:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich13.webp",
    image14:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich14.webp",
    image15:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-sandwich15.webp",
  },
  // BIM FORMATION
  {
    id: 14,
    tags: ["Branding", "Web&Ecommerce"],
    route: "bim-formation",
    projectName: "Bim Formation",
    projectSubtitleEs: "Bim para el crecimiento profesional",
    projectSubtitleEn: "BIM for professional growth",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-bim.webp",
    description1Es:
      "BIM Formation es una plataforma de formación online especializada en Building Information Modeling (BIM), con sede en Sevilla, España. Su enfoque práctico y su compromiso con la rápida inserción laboral la distinguen en el mercado. Fuimos convocados para desarrollar una imagen de marca vibrante, dirigida a jóvenes arquitectos con necesidades reales.",
    description1En:
      "BIM Formation is an online training platform specializing in Building Information Modeling (BIM), based in Seville, Spain. Its practical approach and commitment to rapid employment distinguish it in the market. We were tasked with developing a vibrant brand image aimed at young architects with real needs.",
    description2Es:
      "Seleccionamos una paleta de colores contrastantes y frescos, destacando el verde como color principal para transmitir un sentido de crecimiento, innovación y aspiración. Combinamos este tono con un azul académico, que en su variante saturada aporta mayor impacto. En cuanto a los elementos gráficos, nos inspiramos en la interfaz del programa BIM, utilizando formas 3D, líneas de planos y cuadrículas. Rediseñamos estos elementos para adaptarlos al branding, logrando asi un toque único y contemporáneo.",
    description2En:
      "We selected a palette of contrasting and fresh colors, highlighting green as the primary color to convey a sense of growth, innovation, and aspiration. This tone was combined with an academic blue, which, in its saturated variant, provides greater impact. For the graphic elements, we drew inspiration from the BIM software interface, using 3D shapes, plan lines, and grids. We redesigned these elements to fit the branding, achieving a unique and contemporary touch.",
    description3Es:
      "En cuanto al diseño web, nos enfocamos en crear una experiencia dinámica y didáctica, utilizando animaciones y elementos gráficos que guían al usuario desde el inicio. Además, la navegación está pensada para facilitar la exploración de los contenidos, enfatizando el concepto de eficiencia que caracteriza a la marca.",
    description3En:
      "Regarding the web design, we focused on creating a dynamic and educational experience, using animations and graphic elements that guide the user from the start. Additionally, the navigation is designed to facilitate content exploration, emphasizing the concept of efficiency that characterizes the brand.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-bim.webp",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Logo",
    service2En: "Logo",
    service3Es: "Branding",
    service3En: "Branding",
    service4Es: "Web",
    service4En: "Website",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Sevilla, Spain",
    locationEn: "Sevilla, España",
    templateNumber: "1",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-bimformation1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-bimformation2.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-bimformation3.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-bimformation4.webp",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-bimformation5.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-bimformation6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-bimformation7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-bimformation8.webp",
  },
  // LOOP3
  {
    id: 15,
    tags: ["Branding", "Web&Ecommerce"],
    route: "loop3",
    projectName: "Loop3",
    projectSubtitleEs: "Rompiendo las barreras digitales",
    projectSubtitleEn: "Breaking digital barriers",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-loop.png",
    description1Es:
      "Loop es un estudio de soluciones informáticas con sede en Buenos Aires, Argentina, cuyo objetivo es impulsar la transformación digital de empresas a través de una mirada joven, arriesgada y ambiciosa. Fuimos convocados con total libertad para crear la imagen desde cero, y comenzamos por el proceso del naming.",
    description1En:
      "Loop is a computer solutions studio based in Buenos Aires, Argentina, with the goal of driving digital transformation for companies through a young, bold, and ambitious perspective. We were given complete freedom to create the brand from scratch, starting with the naming process.",
    description2Es:
      "Jugamos con distintas palabras del universo de la programación que nos hablaran de transformaciones y además, necesitábamos una palabra que resultara simple de pronunciar en distintos idiomas.  Así es como surge Loop, incorporando el número 3 para representar a los socios fundadores. Para su construcción, elegimos una tipografía redondeada y llamativa,  la cual intervenimos para crear el símbolo del infinito que refuerza el concepto de la marca.",
    description2En:
      "We played with various programming-related terms that spoke to transformations and needed a word that was easy to pronounce in different languages. This led to the creation of Loop, incorporating the number 3 to represent the founding partners. For its design, we chose a rounded and striking typeface, which we modified to create the infinity symbol that reinforces the brand concept.",
    description3Es:
      "En cuanto al branding, optamos por una paleta de colores fuertes, donde el negro predominó en los fondos para resaltar la audacia de la marca. Se lo combinó con elementos curvos y líneas finas que aportaron profundidad y juventud al diseño. Además, utilizamos distintos degradados que transmiten modernidad y transformación, junto con iconografías sólidas que completaron la personalidad de la marca.",
    description3En:
      "For the branding, we opted for strong colors, with black predominating in the backgrounds to highlight the brand's boldness. This was combined with curved elements and thin lines that added depth and youthfulness to the design. Additionally, we used various gradients to convey modernity and transformation, along with solid iconography that completed the brand’s personality.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-loop.png",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Logo",
    service2En: "Logo",
    service3Es: "Branding",
    service3En: "Branding",
    service4Es: "Web",
    service4En: "Website",
    service5Es: "Social media",
    service5En: "Social media",
    service6Es: "",
    service6En: "",
    locationEs: "Buenos Aires, Argentina",
    locationEn: "Buenos Aires, Argentina",
    templateNumber: "3",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop3.png",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop5.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop4.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop8.png",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop9.png",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop10.png",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop11.svg",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop12.png",
    image13:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop13.png",
    image14:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop14.png",
    image15:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-loop15.png",
  },
  // OVT
  {
    id: 16,
    tags: ["Branding"],
    route: "ovt",
    projectName: "OVT",
    projectSubtitle: {
      es: "",
      en: "",
    },
    projectSubtitleEs: "Una óptica, dos perspectivas",
    projectSubtitleEn: "One optics, two Perspectives",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-ovt.png",
    description1Es:
      "OVT es una óptica con sede en Buenos Aires la cual nos contactó para renovar su imagen con dos objetivos y públicos diferenciados: impulsar las ventas digitales entre el público joven y promover sus programas de salud visual en el sector institucional.",
    description1En:
      "OVT is an optical company based in Buenos Aires that approached us to refresh its image with two distinct objectives and target audiences: boosting digital sales among young people and promoting their visual health programs in the institutional sector.",
    description2Es: `Para afrontar este desafío, identificamos puntos en común que nos permitieran satisfacer ambas necesidades y mantener la coherencia de la identidad de la marca. Creamos una imagen de confianza y cercanía mediante el uso de filtros fotográficos claros y formas redondeadas que reflejan la esencia del logo, pensados para su aplicación en fondos o como misceláneas.`,
    description2En: `To tackle this challenge, we identified common points that allowed us to address both needs while maintaining brand identity consistency. We created an image of trust and approachability through the use of clear photographic filters and rounded shapes that reflect the essence of the logo, designed for application on backgrounds or as miscellaneous elements.`,
    description3Es:
      "En cuanto a los colores, seleccionamos tonos suaves pero contrastantes para llamar la atención sin desviar el enfoque del producto. Para los programas de salud, elegimos una paleta corporativa en gamas azules, transmitiendo seriedad y profesionalismo. En el plano digital, adoptamos tonos lúdicos en verde, rosa y violeta para conectar con el público joven. Complementamos el branding con tipografías de estilo sans-serif y una iconografía actual, dando énfasis al estilo infográfico en los programas de salud, mientras que en las redes sociales utilizamos onomatopeyas y situaciones lúdicas para generar conexión con el público jóven.",
    description3En:
      "For colors, we selected soft yet contrasting tones to draw attention without distracting from the product. For the health programs, we chose a corporate palette in shades of blue, conveying seriousness and professionalism. In the digital realm, we adopted playful tones in green, pink, and violet to connect with the young audience. We complemented the branding with sans-serif typefaces and contemporary iconography, emphasizing an infographic style for the health programs, while using onomatopoeias and playful situations on social media to engage with the younger audience.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-ovt.png",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Branding",
    service2En: "Branding",
    service3Es: "Social media",
    service3En: "Social media",
    service4Es: "Cartelería",
    service4En: "Signage",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Buenos Aires, Argentina",
    locationEn: "Buenos Aires, Argentina",
    templateNumber: "3",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt3.svg",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt5.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt4.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt8.png",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt9.png",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt10.png",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt11.png",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt12.png",
    image13:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt13.png",
    image14:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt14.png",
    image15:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-ovt15.png",
  },
  // RAPID ENVIA
  {
    id: 17,
    tags: ["Apps"],
    route: "rapid-envia",
    projectName: "Rapid envía",
    projectSubtitleEs: "Simplicidad en cada transferencia",
    projectSubtitleEn: "Simplicity in every transfer",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-rappid.png",
    description1Es:
      "Rapid Envía es una plataforma de envío de dinero internacional, con sede en Santiago, Chile. La misma se destaca por ofrecer transferencias inmediatas y comisiones bajas. Fuimos convocados para el desarrollo completo de la aplicación, desde la interfaz hasta su diseño.",
    description1En:
      "Rapid Envía is an international money transfer platform based in Santiago, Chile. It stands out for offering immediate transfers and low fees. We were tasked with the complete development of the application, from the interface to the design.",
    description2Es:
      "Nuestro objetivo fue crear una interfaz intuitiva y fácil de navegar, colocando todas las herramientas necesarias al alcance del usuario y simplificando cada operación al máximo. En el diseño, nos concentramos en respaldar la funcionalidad. Optamos por tonos violetas fríos para lograr una apariencia moderna y tecnológica, y complementamos con un tono celeste claro para destacar puntos clave. Los fondos, con elementos curvos inspirados en el logo, transmiten una sensación de fluidez y rapidez en la experiencia del usuario.",
    description2En:
      "Our goal was to create an intuitive and easy-to-navigate interface, placing all necessary tools within the user's reach and simplifying each operation to the maximum. In the design, we focused on supporting functionality. We chose cool violet tones to achieve a modern and technological appearance, complemented by a light sky blue to highlight key points. The backgrounds, featuring curved elements inspired by the logo, convey a sense of fluidity and speed in the user experience.",
    description3Es: "",
    description3En: "",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-rappid.png",
    service1Es: "Diseño de app",
    service1En: "App Design",
    service2Es: "",
    service2En: "",
    service3Es: "",
    service3En: "",
    service4Es: "",
    service4En: "",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Santiago, Chile",
    locationEn: "Santiago, Chile",
    templateNumber: "1",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-rappid1.png",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-rappid2.png",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-rappid3.png",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-rappid4.png",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-rappid5.png",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-rappid6.png",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-rappid7.png",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-rappid8.png",
  },
  // SUPRA
  {
    id: 18,
    tags: ["Branding", "Web&Ecommerce"],
    route: "supra",
    projectName: "Supra",
    projectSubtitleEs: "Vanguardia en Tiktok Marketing",
    projectSubtitleEn: "Vanguard in TikTok Marketing",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-supra.webp",
    description1Es:
      "Supra es una agencia con sedes en Buenos Aires, México y Miami que lidera el camino en la creación de campañas de marketing en TikTok. Su enfoque centrado en resultados, respaldado por tecnología de vanguardia y una plataforma propia de análisis, impulsa el éxito tanto de las campañas como de los talentos que gestionan.",
    description1En:
      "Supra is an agency with offices in Buenos Aires, Mexico City, and Miami that leads the way in creating TikTok marketing campaigns. Its results-focused approach, supported by cutting-edge technology and its own analytics platform, drives the success of both campaigns and the talents it manages.",
    description2Es: `Fuimos convocados para rediseñar el branding y la web de la agencia, con el objetivo de proyectar la innovación y el éxito Supra lleva al competitivo mundo de Tiktok. Para ello, creamos una estética llamativa, donde las tipografías san serif, grandes y claras, toman el protagonismo. El contraste entre el negro tipográfico y el blanco de fondo llevan a la marca a un universo moderno y limpio. Para puntos focales, elegimos el magenta, un color vibrante y enérgico que rompe con el monocromía. A su vez, sumamos color con la elección de imágenes saturadas que capturan la esencia de la generación Z: audaces y relajados. Completamos la imagen de la marca con el desarrollo de gráficos estadísticos y números de gran tamaño, resaltando el enfoque de la empresa en resultados y métricas.`,
    description2En: `We were commissioned to redesign the agency’s branding and website, aiming to project the innovation and success Supra brings to the competitive world of TikTok. To achieve this, we created a striking aesthetic, with large, clear sans-serif typefaces taking center stage. The contrast between the typographic black and white background places the brand in a modern and clean universe. For focal points, we chose magenta, a vibrant and energetic color that breaks the monochrome. Additionally, we incorporated color through saturated images that capture the essence of Generation Z: bold and relaxed. We completed the brand image with the development of large-scale statistical graphics and numbers, highlighting the company’s focus on results and metrics.`,
    description3Es: "",
    description3En: "",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-supra.webp",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Branding",
    service2En: "Branding",
    service3Es: "Web",
    service3En: "Website",
    service4Es: "",
    service4En: "",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Buenos Aires, Argentina",
    locationEn: "Buenos Aires, Argentina",
    templateNumber: "2",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra3.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra2.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra4.webp",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra5.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra8.webp",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra9.webp",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra10.webp",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra11.webp",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-supra12.webp",
  },
  // PINKINTON
  {
    id: 19,
    tags: ["Branding", "Web&Ecommerce"],
    route: "pinkinton",
    projectName: "Pinkinton",
    projectSubtitleEs: "Sastres financieros",
    projectSubtitleEn: "Financial tailors",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-pinkinton.webp",
    description1Es: `Pinkinton es un estudio financiero ubicado en Montevideo, Uruguay, que se distingue por su enfoque innovador, autodenominándose "Sastres Financieros", ya que ofrece soluciones financieras adaptadas a las necesidades de sus clientes con gustos sofisticados.
      Fuimos convocados para realizar el rebranding de la marca y diseñar su página web. `,
    description1En: `Pinkinton is a financial firm based in Montevideo, Uruguay, known for its innovative approach and self-styled as "Financial Tailors," offering financial solutions tailored to clients with sophisticated tastes. We were commissioned to rebrand the company and design its website.`,
    description2Es:
      "El objetivo era atraer a un público específico y asociar los servicios financieros de Pinkinton con productos de alta gama, como autos deportivos, arte y trajes a medida. Creamos una imagen sofisticada y minimalista, con fuerte presencia del blanco para aportar liviandad y acompañamos con un azul profundo y bordó para transmitir profesionalismo. En términos tipográficos, optamos por una serif elegante junto con una sans serif ligera para añadir un toque de modernidad.",
    description2En:
      "The goal was to attract a specific audience and associate Pinkinton’s financial services with high-end products such as sports cars, art, and bespoke suits. We created a sophisticated and minimalist image, prominently featuring white to provide a sense of lightness, complemented by deep blue and burgundy to convey professionalism. For typography, we chose an elegant serif combined with a light sans-serif to add a touch of modernity.",
    description3Es:
      "Tanto en la página web como en la papelería institucional utilizamos pocos elementos para lograr una apariencia clara y pulcra. Las imágenes de alta calidad aportaron personalidad y el diseño de gráficos financieros desempeña.",
    description3En:
      "Both the website and institutional stationery employed minimal elements to achieve a clear and pristine appearance. High-quality images added personality, and the design of financial graphics played a crucial role.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-pinkinton.png",
    service1Es: "Branding",
    service1En: "Branding",
    service2Es: "Web",
    service2En: "Website",
    service3Es: "Papelería",
    service3En: "Stationery",
    service4Es: "Editorial",
    service4En: "Editorial",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Montevideo, Uruguay",
    locationEn: "Montevideo, Uruguay",
    templateNumber: "2",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton2.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton3.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton4.webp",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton5.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton8.webp",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton9.webp",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton10.webp",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton11.webp",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-pinkinton12.webp",
  },
  // LA CHURRERIA
  {
    id: 20,
    tags: ["Branding"],
    route: "la-churreria",
    projectName: "La Churrería",
    projectSubtitleEs: "Churros, Donuts & Toppings",
    projectSubtitleEn: "Churros, Donuts & Toppings",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-churreria.png",
    description1Es:
      "La Churrería by Pachi surge como una divertida propuesta estacional en Punta del Este, Uruguay. Impulsada por un apasionado gastronómico, esta idea toma vida inspirada en su hija Pachi, con el objetivo de brindar una experiencia deliciosa y divertida durante el verano. ",
    description1En:
      "La Churrería by Pachi emerges as a fun, seasonal venture in Punta del Este, Uruguay. Driven by a passionate gastronome, this idea comes to life inspired by his daughter Pachi, with the goal of offering a delicious and enjoyable experience during the summer.",
    description2Es:
      "Nuestro objetivo fue crear una identidad de marca integral que capturara la esencia emocional y personal detrás de este emprendimiento. Comenzamos diseñando un logotipo con una tipografía fluida y alegre para transmitir una idea descontracturada. Para la paleta de colores, elegimos tonos vibrantes y contrastantes con un toque femenino, reflejando la esencia lúdica y golosa del lugar. La gráfica se completó con ilustraciones de churros, donuts y chocolates, creando patrones visuales que cobraron vida en el packaging y otras piezas.",
    description2En:
      "Our objective was to create a comprehensive brand identity that captured the emotional and personal essence behind this venture. We started by designing a logo with a fluid and cheerful typeface to convey a relaxed vibe. For the color palette, we chose vibrant and contrasting tones with a feminine touch, reflecting the playful and indulgent essence of the place. The graphics were completed with illustrations of churros, donuts, and chocolates, creating visual patterns that came to life in the packaging and other materials.",
    description3Es:
      "Participamos tanto en el diseño físico, como en la creación de la cartelería y fachada del local, así como en el desarrollo de la presencia en redes sociales, manteniendo esta esencia en ambos entornos.",
    description3En:
      "We were involved in both the physical design, including the signage and façade of the location, as well as developing the social media presence, maintaining this essence in both environments.",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-churreria.webp",
    service1Es: "Conceptual",
    service1En: "Conceptual",
    service2Es: "Logo",
    service2En: "Logo",
    service3Es: "Branding",
    service3En: "Branding",
    service4Es: "Social",
    service4En: "Social",
    service5Es: "Packaging",
    service5En: "Packaging",
    service6Es: "",
    service6En: "",
    locationEs: "Punta del Este, Uruguay",
    locationEn: "Punta del Este, Uruguay",
    templateNumber: "2",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria2.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria3.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria4.webp",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria5.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria8.webp",
    image9:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria9.webp",
    image10:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria10.webp",
    image11:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria11.webp",
    image12:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-churreria12.webp",
  },
  // ACEPRO
  {
    id: 21,
    tags: ["Apps"],
    route: "acepro",
    projectName: "Acepro",
    projectSubtitleEs: "Experiencia automotriz 4.0",
    projectSubtitleEn: "Automotive experience 4.0",
    heroImage:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-desk-acepro.png",

    description1Es:
      "Acepro tools, desarrollada en Santiago, Chile, es una aplicación líder en soluciones digitales automotrices, destacándose por su amplia gama de dispositivos originales y una plataforma para programación de smart keys. Nuestro reto fue diseñar una aplicación que fuera atractiva, permitiendo a los usuarios acceder a todas sus funciones de manera sencilla.",
    description1En:
      "Acepro Tools, developed in Santiago, Chile, is a leading application in automotive digital solutions, distinguished by its wide range of original devices and a platform for smart key programming. Our challenge was to design an application that was both attractive and allowed users to access all its features easily.",
    description2Es:
      "Para lograrlo, investigamos acerca de las necesidades de nuestra audiencia y luego, creamos diagramas de uso que nos ayudaron en el proceso de diseño.",
    description2En:
      "To achieve this, we researched our audience’s needs and then created usage diagrams to guide the design process. We developed the home screen and user profiles before diving into the design of secondary screens, menus, and interactive elements. For the visual aspect, we chose a deep blue tone as the primary color, used in various shades. This color added a touch of professionalism, and by using it as a background, we provided depth and modernity through textures inspired by the logo. Additionally, we created a customized iconography with a linear and minimalist style, which facilitated the understanding of certain parameters and actions within the application.",
    description3Es:
      "Creamos un home y perfiles de usuario, para después adentrarnos en el diseño de las pantallas secundarias, menús y elementos interactivos. En cuanto al aspecto visual, elegimos un tono azul profundo como color principal, el cual se utilizó en sus distintas escalas. Este tono agregó un toque de profesionalismo, y al usarlo como fondo, logramos darle profundidad y modernidad mediante la incorporación de texturas inspiradas en elementos del logotipo. Además, creamos una iconografía personalizada de estilo lineal y minimalista, lo que facilitó la comprensión de ciertos parámetros y acciones dentro de la aplicación.",
    description3En: "",
    imgPreview:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-hero-mob-acepro.webp",
    service1Es: "Diseño de app",
    service1En: "App Design",
    service2Es: "",
    service2En: "",
    service3Es: "",
    service3En: "",
    service4Es: "",
    service4En: "",
    service5Es: "",
    service5En: "",
    service6Es: "",
    service6En: "",
    locationEs: "Santiago, Chile",
    locationEn: "Santiago, Chile",
    templateNumber: "1",
    image1:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-acepro1.webp",
    image2:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-acepro2.webp",
    image3:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-acepro3.webp",
    image4:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-acepro4.webp",
    image5:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-acepro5.webp",
    image6:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-acepro6.webp",
    image7:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-acepro7.webp",
    image8:
      "https://raw.githubusercontent.com/materadiego/vernal-studio/main/src/Assets/Images/projects-acepro8.webp",
  },
];

export const getProjects = (tag) => {
  return new Promise((resolve, reject) => {
    const filteredProjects = projects.filter((project) =>
      project.tags.includes(tag)
    );
    setTimeout(() => {
      if (tag) {
        resolve(filteredProjects);
      } else {
        resolve(projects);
      }
    }, 1500);
  });
};

export const getCategories = () => {
  return new Promise((resolve, reject) => {
    const all = projects.length;
    const branding = projects.filter((project) =>
      project.tags.includes("Branding")
    ).length;
    const web = projects.filter((project) =>
      project.tags.includes("Web&Ecommerce")
    ).length;
    const apps = projects.filter((project) =>
      project.tags.includes("Apps")
    ).length;
    const categoriesNumber = [all, branding, web, apps];
    resolve(categoriesNumber);
  });
};

export const getProject = (projectRoute) => {
  return new Promise((resolve, reject) => {
    const selectedProject = projects.find(
      (project) => project.route === projectRoute
    );
    resolve(selectedProject);
  });
};

export const getProjectById = (projectId) => {
  return new Promise((resolve, reject) => {
    const selectedProject = projects.find(
      (project) => project.id === projectId
    );
    resolve(selectedProject);
  });
};
